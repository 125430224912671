// @ts-nocheck
import React, { ReactNode } from 'react';
import { Container as BaseContainer } from '@material-ui/core';
import clsx from 'clsx';

import './styles.sass';

type ContainerType = {
  className?: string;
  children: ReactNode;
  disableGutters?: boolean;
  wide?: boolean;
};

/**
 *
 * @param {ContainerType} props
 * @returns {JSX}
 */
function Container(props: ContainerType): React.ReactElement {
  return (
    <BaseContainer
      className={clsx(
        'base-container',
        props.className,
        props.disableGutters && 'base-container-disable-gutters',
        props.wide && 'base-container-wide',
      )}
      fixed
    >
      {props.children}
    </BaseContainer>
  );
}

Container.defaultProps = {
  className: '',
  disableGutters: false,
  wide: false,
};

export default Container;
