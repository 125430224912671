import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Hidden, Drawer, Grid } from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Navigation from 'sections/Header/Navigation';
import { logoOriginalSmall } from './images';

import clsx from 'clsx';
import content from '_constants/content.json';

import './styles.sass';

function Header(props: { isHawaiiUser: Boolean }): React.ReactElement {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScrollChange = useCallback((): void => {
    const hasBeenScrolled = window.scrollY > 35;
    if (isScrolled !== hasBeenScrolled) {
      window.requestAnimationFrame(() => setIsScrolled(hasBeenScrolled));
    }
  }, [isScrolled, setIsScrolled]);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollChange);

    return (): void => {
      document.removeEventListener('scroll', handleScrollChange);
    };
  }, [handleScrollChange]);

  const onToggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const onScrollTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <header
      id="stitch"
      className={clsx({
        scrolled: isScrolled,
      })}
    >
      <Hidden smUp>
        <Drawer anchor="top" open={isMenuOpen} onClose={onToggle}>
          <Box className="drawable-wrapper" onClick={onToggle}>
            <CloseRoundedIcon className="menu-icon-close" />
            <ul className="drawable-nav">
              {content.menu.map(item => {
                if (item.external)
                  return (
                    <li key={item.path} className={clsx({ 'hide': item.locationRestricted && !props.isHawaiiUser })}>
                      <a href={item.path} target="_blank" rel="noopener noreferrer">
                        {item.title}
                      </a>
                    </li>
                  );
                else
                  return (
                    <li key={item.path} className={clsx({ 'hide': item.locationRestricted && !props.isHawaiiUser })}>
                      <Link to={item.path} onClick={onScrollTop}>
                        {item.title}
                      </Link>
                    </li>
                  );
              })}
            </ul>
          </Box>
        </Drawer>
        <Grid container>
          <Grid item xs={4}>
            <MenuRoundedIcon className="menu-icon" onClick={onToggle} />
          </Grid>
          <Grid item xs={4} container alignItems="center" justify="center" className="logo-mobile">
            <Link to="/" onClick={onScrollTop}>
              <img src="/images/logo/logo-white@2x.png" alt="Logo" />
            </Link>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only="xs">
        <div className="logo-wrapper">
          <Link
            to="/"
            className="app-logo"
            onClick={onScrollTop}
          >
            <img src={logoOriginalSmall} alt="logo" />
          </Link>
        </div>
        <Navigation isHawaiiUser={props.isHawaiiUser} />
      </Hidden>
    </header>
  );
}

export default Header;
