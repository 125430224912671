import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import './styles.sass';

const LOADING_TEXT = 'Loading...';

const Loading = (props: any) => {
  const {
    style,
    isText,
    children,
    isLoading,
    spinnerClassName,
    showContentDuringLoading,
  } = props;

  return (
    <div
      style={style}
      className="loader-wrapper"
    >
      {isLoading && (
        <div className="loader-overlay">
          {isText
            ? LOADING_TEXT
            : (
              <CircularProgress className={spinnerClassName}/>
            )}
        </div>
      )}
      <div className="loader-content">
        {showContentDuringLoading
          ? children
          : !isLoading && children}
      </div>
    </div>);
};

Loading.propTypes = {
  showContentDuringLoading: PropTypes.bool,
  spinnerClassName: PropTypes.string,
  spinnerSize: PropTypes.number,
  isLoading: PropTypes.bool,
  isText: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
    PropTypes.number,
    PropTypes.element,
  ]),
};

Loading.defaultProps = {
  style: {},
  isText: false,
  children: null,
  isLoading: false,
  spinnerClassName: '',
  showContentDuringLoading: false,
};

export {
  Loading,
};
