import React, { useState, useEffect } from "react";
import { Grid, Link } from "@material-ui/core";
import {
  Button,
  TextField,
} from "components";
import { callBackendAPI } from "utils/joinLaunch";


import './styles.sass';
export type JoinLaunchFormProps = {
  dark: boolean,
};


function JoinLaunchForm(props: JoinLaunchFormProps): React.ReactElement {
  const { dark } = props;
  const [joinLaunchEmail, setJoinLaunchEmail] = useState("");
  const [joinLaunchEmailError, setJoinLaunchEmailError] = useState(false);
  const [joinLaunchEmailHelperText, setJoinLaunchEmailHelperText] = useState(
    ""
  );
  useEffect(() => {
    if (joinLaunchEmailHelperText === "Email added to the subscribers list.") {
      const removeHelperText = setTimeout(() => {
        setJoinLaunchEmailError(false);
        setJoinLaunchEmailHelperText("");
      }, 5000);
      return () => clearTimeout(removeHelperText);
    }
  }, [joinLaunchEmailHelperText]);
  const handleJoinLaunchEmailFocus = (newValue: string) => {
    setJoinLaunchEmailError(false);
    setJoinLaunchEmailHelperText("");
  };
  const handleJoinLaunchEmailChange = (newValue: string) => {
    setJoinLaunchEmail(newValue);
  };
  const isValidEmailAddress = (email: string) => !!email.match(/.+@.+\..+/);
  const handleJoinLaunchEmailBlur = (email: string) => {
    if (email === "") {
      setJoinLaunchEmailError(false);
      setJoinLaunchEmailHelperText("");
    } else {
      if (isValidEmailAddress(email)) {
        setJoinLaunchEmailError(false);
        setJoinLaunchEmailHelperText("");
      } else {
        setJoinLaunchEmailError(true);
        setJoinLaunchEmailHelperText("Please enter a valid email address");
      }
    }
  };
  const joinLaunchOnClick = () => {
    if (joinLaunchEmail) {
      if (isValidEmailAddress(joinLaunchEmail)) {
        setJoinLaunchEmailError(false);
        setJoinLaunchEmailHelperText("Adding Email to subscribers list...");
        callBackendAPI(joinLaunchEmail)
          .then(response => {
            if (response.status === 200) {
              setJoinLaunchEmail("");
              setJoinLaunchEmailError(false);
            } else setJoinLaunchEmailError(true);

            return response.json();
          })
          .then(response => {
            response.message = response.message.replace(
              "Use PUT to insert or update list members.",
              ""
            );
            setJoinLaunchEmailHelperText(response.message);
          })
          .catch(error => {
            setJoinLaunchEmailError(true);
            setJoinLaunchEmailHelperText(
              "Unexpected Error Occurred! Please try again later..."
            );
          });
      }
    } else {
      setJoinLaunchEmailError(true);
      setJoinLaunchEmailHelperText("Please enter an email address");
    }
  };

  return (
    <Grid
      item
      container
      alignItems="flex-start"
      justify="flex-start"
      className="join-launch-form"
    >
      <Grid item xs={12} sm={7}>
        <TextField
          onBlur={handleJoinLaunchEmailBlur}
          onFocus={handleJoinLaunchEmailFocus}
          onChange={handleJoinLaunchEmailChange}
          value={joinLaunchEmail}
          error={joinLaunchEmailError}
          helperText={joinLaunchEmailHelperText}
          dark={dark}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Link onClick={joinLaunchOnClick} underline="none">
          <Button btnColor="gradient" className="launch-btn">
            <span>Update Me</span>
          </Button>
        </Link>
      </Grid>
    </Grid>
  )
}

JoinLaunchForm.defaultProps = {
  dark: false,
};

export default JoinLaunchForm;
