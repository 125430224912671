import React from 'react';
import clsx from 'clsx';

import './styles.sass';

export type CurveProps = {
  reverse?: boolean;
};

/**
 * @returns {JSX}
 */
function Curve(props: CurveProps): React.ReactElement {
  const curveDarkImage = "data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMDI0IDk5IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgo8cGF0aCBkPSJtLTIuMzEyOGUtMTEgLTcuMTA1NGUtMTVjMTI0LjQgNDMuNDk4IDMwNy44OCA3MS4wMDIgNTEyLjUxIDcxLjAwMiAyMDQuMDkgMCAzODcuMTMtMjcuMzU4IDUxMS41MS03MC42NTNsLTAuMDE0NyA5OS4wMzhoLTEwMjR6IiBmaWxsPSIjMzI0MjREIi8+CjwvZz4KPC9zdmc+Cg==";

  return (
    <div className={clsx('curve', { reverse: props.reverse })}>
      {props.reverse && <div className="reverse-gap" />}
      <img src={curveDarkImage} alt="" />
    </div>
  );
}

export default Curve;
