import React, { useCallback } from 'react';
import chunk from 'lodash.chunk';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import content from '_constants/content.json';


function FooterLinks(props: { isHawaiiUser: Boolean }): React.ReactElement {
  const menu = [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Plan',
      link: '/plan',
    },
    {
      label: 'Get Started',
      link: '/get-started',
    },
    {
      label: 'Become a partner',
      link: 'https://forms.mobi.com/210553952845056',
      external: true,
      showInHawaii: true
    },
    {
      label: 'About',
      link: '/about',
    },
    {
      label: 'Shop',
      link: '/shop',
    },
    {
      label: 'Stores',
      link: '/stores',
      showInHawaii: true
    },
    {
      label: 'Become a partner',
      link: 'https://forms.mobi.com/210553952845056',
      external: true,
      showOutsideOfHawaii: true
    },
    {
      label: 'Help',
      link: '/help',
    },
    {
      label: 'Careers',
      link: content.social.linkedin,
      external: true
    },
    {
      label: 'Login',
      link: "https://mobicustomercare.com/Support/MyAccount/tabid/1997/Default.aspx",
      external: true
    },
  ];

  const onScrollTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="footer-link-section">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        {
          chunk(menu, 4).map((chunk, index) => (
            <Grid
              item
              xs={12}
              sm="auto"
              key={index}
            >
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                {
                  chunk.map((item) => {
                    return (item.showInHawaii && !props.isHawaiiUser || item.label == 'Skip') ||
                        (item.showOutsideOfHawaii && props.isHawaiiUser || item.label == 'Skip') ? null : (
                      <Grid key={item.label} item xs={12} sm={12} container>
                        <div className={"footer-link"}>
                          {
                            (item.external) ? (
                              <a href={item.link} target="_blank" rel="noopener noreferrer">
                                {item.label}
                              </a>
                            ) : (
                                <Link to={item.link} onClick={onScrollTop}>
                                  {item.label}
                                </Link>
                              )
                          }
                        </div>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Grid>
          ))
        }
      </Grid>
    </div >
  );
}

export default FooterLinks;
