import React, { ReactNode, ReactElement, useState, useCallback, useEffect, memo } from 'react';
import { Grid, Box, Hidden } from '@material-ui/core';
import { Container, Image } from 'components';

import './styles.sass';

type MainSectionProps = {
  title: string | ReactNode;
  text?: string | ReactNode;
  image?: string;
  children?: ReactNode;
};

const SCROLL_POSITION = 63;

/**
 * @returns {ReactElement}
 */
function MainSection(props: MainSectionProps): ReactElement {
  const [scrollPosition, setScrollPosition] = useState(SCROLL_POSITION);
  const [logoOpacity, setLogoOpacity] = useState('1');

  const handleScrollChange = useCallback((): void => {
    window.requestAnimationFrame(() => {
      if (window.scrollY <= 80) {
        const position = Math.floor(SCROLL_POSITION - window.scrollY / 2);
        const opacity = (1 - ((window.scrollY) / 100)).toFixed(1);

        if (scrollPosition !== position) {
          setScrollPosition(position);
        }
        if (logoOpacity !== opacity) {
          setLogoOpacity(opacity);
        }
      }
    });
  }, [scrollPosition, logoOpacity, setScrollPosition, setLogoOpacity]);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollChange);

    return (): void => {
      document.removeEventListener('scroll', handleScrollChange);
    };
  }, [handleScrollChange]);

  return (
    <div className="main-section">
      <Container>
        <Grid container alignItems="center" direction="column">
          <Hidden smUp>
            <div className="logo">
              <Image src="/images/logo/logo-mobile.png" style={{ maxWidth: `${scrollPosition}px`, opacity: logoOpacity }} alt="Logo" />
            </div>
          </Hidden>
          <h1 className="title">
            {props.title}
          </h1>
          {props.text && (
            <p className="text">
              {props.text}
            </p>
          )}
        </Grid>
        {props.image && (
          <Box display="flex" flexWrap="wrap" justifyContent="center" className="image-wrapper">
            <div className="image">
              <Image src={props.image} />
            </div>
          </Box>
        )}
      </Container>
      {props.children}
    </div>
  );
}

export default memo(MainSection);
