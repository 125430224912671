import React, { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import './styles.sass';

type LayoutType = {
  children: ReactNode;
  className?: string;
  footer?: ReactNode;
};

/**
 *
 * @param {LayoutType} props
 * @returns {JSX}
 */
function Layout(props: LayoutType): React.ReactElement {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={`layout ${props.className}`}>
      {props.children}
      {props.footer}
    </div>
  );
}

Layout.defaultProps = {
  className: '',
  footer: null,
};

export default Layout;
