import React from 'react';
import MUITextField from '@material-ui/core/TextField';

import './styles.sass';
export type TextFieldProps = {
  error: boolean,
  helperText: String,
  onChange: Function,
  onBlur: Function,
  onFocus: Function,
  value: String,
  dark: boolean,
};


function TextField(props: TextFieldProps): React.ReactElement {
  const { error, helperText, onChange, onBlur, onFocus, value, dark } = props;
  return (
    <MUITextField
      variant="filled"
      onChange={(e) => { onChange && onChange(e.target.value) }}
      onBlur={(e) => { onBlur && onBlur(e.target.value) }}
      onFocus={(e) => { onFocus && onFocus(e.target.value) }}
      value={value}
      fullWidth
      classes={{
        root: "mui-text-field",
      }}
      label="Your email address"
      InputLabelProps={
        {
          classes: {
            root: "input-label-root",
            shrink: "input-label-shrink",
            formControl: "input-label-formcontrol",
            focused: "input-label-focused",
          }
        }
      }
      FormHelperTextProps={
        {
          classes: {
            root: dark ? 'helper-text-root-dark' : 'helper-text-root',
            error: 'helper-text-error'
          }
        }
      }
      error={error}
      helperText={helperText}
      InputProps={{
        classes: {
          root: dark ? 'input-root-dark' : 'input-root',
          input: 'input-element',
          error: 'input-error'
        },
        disableUnderline: true,
      }}
    />
  )
}

TextField.defaultProps = {
  error: false,
  helperText: "",
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  value: "",
  dark: false,
};

export default TextField;
