import React, { useEffect, useState } from 'react';
import isString from 'lodash/isString';
import clsx from 'clsx';


export type LazyImgProps = {
  src: string | string[];
  alt?: string;
  wrapperClassName?: string;
  className?: string;
  responsive?: boolean;
  style: object;
  onClick: object;
};

const placeHolder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=";

const sizes = ["", "@2x", "@3x"];

function Image(props: LazyImgProps): React.ReactElement {
  const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });

    return width;
  }
  let windowWidth = useWindowWidth();

  let srcSet = props.src;

  if (windowWidth && isString(srcSet) && props.responsive) {
    const [path, ext] = srcSet.split(".");
    if ((ext === "png") || (ext === "jpg")) {
      const small = path + sizes[0] + "." + ext;
      const medium = path + sizes[1] + "." + ext;
      const large = path + sizes[2] + "." + ext;

      const pixelWidth = windowWidth * window.devicePixelRatio;

      if (pixelWidth < 600) srcSet = small;
      if (pixelWidth < 1280) srcSet = medium;
      if (pixelWidth > 1280) srcSet = large;
    }
  }

  return (
    <div className={props.wrapperClassName}>
      <img
        src={isString(srcSet) ? srcSet : placeHolder}
        className={clsx(
          props.className
        )}
        style={props.style}
        alt={props.alt}

      />
    </div>
  );
}

Image.defaultProps = {
  wrapperClassName: "",
  className: "",
  alt: "",
  responsive: true,
  style: {},
  onClick: {}
};

export default Image;
