import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MUIButton, { ButtonTypeMap } from '@material-ui/core/Button';

import './styles.sass';

const gradientTypeColor = 'gradient-border';
export type ButtonType<P extends ButtonTypeMap> = {
  className?: string;
  btnColor?: string;
  children?: string | React.ReactElement;
  disabled?: boolean | undefined;
};

function Button(props: ButtonType<ButtonTypeMap>): React.ReactElement {
  const { className, btnColor, disabled, ...btnProps } = props;

  return (
    <MUIButton
      {...btnProps}
      disableRipple={btnColor === gradientTypeColor}
      className={clsx('stitch-btn', btnColor, className)}
      disabled={disabled}
    />
  )
}

Button.defaultProps = {
  btnColor: 'white',
  disabled: false,
};

Button.propTypes = {
  btnColor: PropTypes.oneOf(['white', 'gradient', gradientTypeColor]),
};

export default Button;
