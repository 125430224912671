

export const callBackendAPI = async (joinLaunchEmail: string) => {
    let url = (process.env.NODE_ENV==="production")?process.env.REACT_APP_JOIN_LAUNCH_API_PROD_URL:process.env.REACT_APP_JOIN_LAUNCH_API_DEV_URL
    if (url===undefined)
        url = "https://mobi.com/api/joinlaunch"
    const request = await fetch(
        url,
        {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            mode: "cors",
            body: new Blob([JSON.stringify({ email: joinLaunchEmail })], { type: 'application/json' })
        });
    return request;
};

export default callBackendAPI;
