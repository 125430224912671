import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from 'components/Button';
import clsx from 'clsx';

import './styles.sass';

const navItems = [
  {
    title: 'Plan',
    path: '/plan',
  },
  {
    title: 'About',
    path: '/about',
  },
  {
    title: 'Shop',
    path: '/shop',
  },
  {
    title: 'Help',
    path: '/help',
  },
  {
    title: 'Stores',
    path: '/stores',
    locationRestricted: true
  },
];

function Navigation(props: { isHawaiiUser: Boolean }): React.ReactElement {
  const location = useLocation();

  const onScrollTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="nav-wrapper flex-row align-center nowrap">
      <ul className="reset-list-styles stitch-nav">
        {navItems.map(item => {
          return (
            <li
              key={item.path}
              className={clsx('nav-item', {
                'active': item.path === location.pathname,
                'hide': item.locationRestricted && !props.isHawaiiUser
              })}
            >
              <Link to={item.path} onClick={onScrollTop}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>

      <Link to="/get-started" onClick={onScrollTop}>
        <Button
          btnColor="gradient-border"
          className="nav-btn"
        >
          Get Started
        </Button>
      </Link>
    </div>
  );
}

export default Navigation;
