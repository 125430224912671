import React from 'react';
import ReactDOM from 'react-dom';
import "@openfonts/nunito-sans_all";
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./third-party/css/swiper.min.css";
import ReactGA from "react-ga";

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
if (trackingId !== undefined) {
  ReactGA.initialize(trackingId);
  ReactGA.set({ hostname: window.location.hostname });
  ReactGA.set({ page: window.location.pathname }); // Update the user's current page
  ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
