import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import Routes from 'routes';
import { createMuiTheme } from '@material-ui/core/styles';
import ErrorBoundary from 'components/ErrorBoundary/Component';
import "./styles/common.sass";


const App: React.FC = () => {
  const breakpointValues = {
    xs: 0,
    sm: 768,
    md: 960,
    lg: 1280,
    xl: 1920,
  };
  const theme = createMuiTheme({ breakpoints: { values: breakpointValues } });
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
          <Routes />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
