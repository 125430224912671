import React from 'react';
import Grid from '@material-ui/core/Grid';

import './styles.sass';

type QuestionsProps = {
  items: {
    question: string;
    answer: string;
  }[];
};

/**
 *
 * @returns {JSX}
 */
function Questions(props: QuestionsProps): React.ReactElement {
  return (
    <Grid container justify="center">
      <Grid item xs={12} md={10}>
        <div className="questions">
          <h2 className="questions-title">
            General Questions
          </h2>
          {props.items.map((item, index) => (
            <div key={String(index)} className="question">
              <h4 className="question-title">{item.question}</h4>
              {
                item.answer.split('\n').map((line, key) => {
                  return <p className="question-answer" key={key}>{line}</p>
                })
              }
              {/* Added two line breaks and the "Frequently Asked Questions" title after the third question-answer */}
              {index === 2 && (
                <>
                  <br />
                  <br />
                  <h2 className="questions-title additional-title">
                    Frequently Asked Questions
                  </h2>
                  {/* FAQs */}
                </>
              )}
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}




export default Questions;
