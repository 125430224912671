import React, { ReactNode, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import './styles.sass';

type WindowScrollType = {
  children: ReactNode;
  className?: string;
  scrollHeight: number;
  opposite?: boolean;
};
function WindowScroll(props: WindowScrollType): React.ReactElement {

  const [isScrolled, setIsScrolled] = useState(false);

  const { opposite } = props;

  const handleScrollChange = useCallback((): void => {
    const hasBeenScrolled = window.scrollY > props.scrollHeight;
    if (isScrolled !== hasBeenScrolled) {
      window.requestAnimationFrame(() => setIsScrolled(hasBeenScrolled));
    }
  }, [isScrolled, setIsScrolled, props.scrollHeight]);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollChange);

    return (): void => {
      document.removeEventListener('scroll', handleScrollChange);
    };
  }, [handleScrollChange]);

  const defaultClassnames = `window-scroll ${props.className}`;

  return (
    <div className={clsx({
      [defaultClassnames]: true,
      show: opposite ? !isScrolled : isScrolled,
    })}>
      {props.children}
    </div>
  );
}

WindowScroll.defaultProps = {
  opposite: false,
};

export default WindowScroll;
