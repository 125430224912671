import { lazy } from 'react';
import AsyncComponentLoader from 'components/AsyncComponentLoader';

export default [
  {
    path: '/style',
    component: () => {
      window.location.replace('https://wiki.mobi.com');
      return null;
    },
  },
  {
    title: 'Plan',
    path: '/plan',
    component: AsyncComponentLoader(lazy(() => import('pages/Plan/index'))),
  },
  {
    title: 'About',
    path: '/about',
    component: AsyncComponentLoader(lazy(() => import('pages/About/index'))),
  },
  {
    title: 'Terms',
    path: '/terms/:version',
    component: AsyncComponentLoader(lazy(() => import('pages/Terms/index'))),
  },
  {
    title: 'Terms',
    path: '/terms',
    component: AsyncComponentLoader(lazy(() => import('pages/Terms/index'))),
  },
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
    component: AsyncComponentLoader(lazy(() => import('pages/PrivacyPolicy/index'))),
  },
  {
    title: 'Shop',
    path: '/shop',
    component: () => {
      window.location.replace('https://shop.mobi.com/collections/frontpage');
      return null;
    },
  },
  {
    title: 'Refer',
    path: '/refer',
    component: () => {
      window.location.replace('https://go.mobi.com/refer-a-friend/');
      return null;
    },
  },
  {
    title: 'Home',
    path: '/home-full',
    component: AsyncComponentLoader(lazy(() => import('pages/HomeFull/index'))),
  },
  {
    title: 'Get Started',
    path: '/get-started',
    component: AsyncComponentLoader(lazy(() => import('pages/GetStarted/index'))),
  },
  {
    title: 'Stores',
    path: '/stores',
    component: AsyncComponentLoader(lazy(() => import('pages/Stores/index'))),
  },
  {
    title: 'Help',
    path: '/help',
    component: AsyncComponentLoader(lazy(() => import('pages/Help/index'))),
  },
  {
    title: 'App Link',
    path: '/applink',
    component: AsyncComponentLoader(lazy(() => import('pages/AppLink/index'))),
  },
  {
    title: 'App Link Forgot Password',
    path: '/applink-forgotpassword',
    component: AsyncComponentLoader(lazy(() => import('pages/AppLinkForgotPassword/index'))),
  },
  {
    title: 'App Link Email',
    path: '/applink-email',
    component: AsyncComponentLoader(lazy(() => import('pages/AppLinkEmail/index'))),
  },
  {
    title: 'Home',
    path: '/',
    component: AsyncComponentLoader(lazy(() => import('pages/Home/index'))),
  },
  {
    component: AsyncComponentLoader(lazy(() => import('pages/NotFound/index'))),
  }
];
