import React, { useEffect, useState } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import history from "utils/history";
import { renderRoutes } from 'react-router-config';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from 'sections/Header';
import Footer from 'sections/Footer';
import { getUserLocation } from 'utils/location';
import ReactGA from "react-ga";
import routes from "./routes";


// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function Routing(): React.ReactElement {
  const [loader, setLoader] = useState(true);
  const [isHawaiiUser, setIsHawaiiUser] = useState(false); // Default behavior: Non-Hawaii User

  useEffect(() => {
    getUserLocation()
      .then((location) =>
        (
          location.hasOwnProperty('region_code')
          && location.region_code === 'HI'
          && location.hasOwnProperty('country_code')
          && location.country_code === 'US'
        ) ? // Condition to check if user location is Hawaii
          setIsHawaiiUser(true) : // Fetched user location. Hawaii user
          setIsHawaiiUser(false)  // Fetched user location. Non-Hawaii user
      )
      .catch(() => setIsHawaiiUser(false)); // Unable to fetch user location. Assuming Non-Hawaii user
  }, [setIsHawaiiUser]);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    const timer = setTimeout(() => {
      setLoader(false);
      document.body.classList.remove('overflow-hidden');
    }, 800);
    return (): void => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <Router history={history}>
      <Route
        render={({ location }): React.ReactElement => (
          <div className="app-wrapper">
            {loader && (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
            <Header isHawaiiUser={isHawaiiUser} />
            <Switch location={location}>
              {renderRoutes(routes, {
                footer: <Footer isHawaiiUser={isHawaiiUser} />,
              })}
            </Switch>
          </div>
        )}
      />
    </Router>
  );
}

export default Routing;

