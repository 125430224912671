import React from 'react';

import { Link } from 'react-router-dom';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { 
  Container, 
  Image, 
  JoinLaunchForm
} from 'components';
import FooterLinks from 'sections/Footer/Links';
import { imageSocialFb, imageSocialInsta, imageSocialTwitter } from './images';

import content from '_constants/content.json'
import './styles.sass';

function Footer(props: { isHawaiiUser: Boolean }): React.ReactElement {
  const showAppStoreBadges = false;
  return (
    <footer>
      <Container>
        <div className="form-section" >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className="item"
          >
            <Grid item sm={8} xs={12}>
              <p className="description text-center launch-soon">
                Get updates on the Mobi beta and launch.
              </p>
            </Grid>
            <Grid item sm={8} xs={12} container>
              <JoinLaunchForm />
            </Grid>
          </Grid>
        </div>
        {
          showAppStoreBadges ? (
            <Grid container className="footer-container" justify="space-between">
              <Grid item xs={12} sm={6} md={7}>
                <div className="footer-container-mobile-link">
                  <Image
                    src="/images/logo/mobi-logo-footer.png"
                    alt="Logo"
                  />
                  <p className="download-the-mobi-ap">Download the Mobi app to start saving.</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={5} container justify="flex-end" className="footer-container-mobile">

                <Hidden only="xs">
                  <a href={content.store.appStore} target="_blank" rel="noopener noreferrer">
                    <Image
                      src="/images/badges/badge-appstore.png"
                      className="footer-container-mobile-badge"
                      alt="App Store"
                    />
                  </a>
                  <Image
                    src="/images/badges/badge-googleplay.png"
                    className="footer-container-mobile-badge"
                    alt="Google Play"
                  />
                </Hidden>
              </Grid>
            </Grid>
          ) : null
        }
        <FooterLinks isHawaiiUser={props.isHawaiiUser} />
        <Grid container justify="center" className="footer-social-section">
          <a
            href={content.social.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={imageSocialFb}
              className="footer-social-section-icon"
              alt="Facebook"
            />
          </a>
          <a
            href={content.social.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={imageSocialInsta}
              className="footer-social-section-icon"
              alt="Instagram"
            />
          </a>
          <a
            href={content.social.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={imageSocialTwitter}
              className="footer-social-section-icon"
              alt="Twitter"
            />
          </a>
          <a
            href={content.social.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="/images/social/social-linkedin.png"
              className="footer-social-section-icon"
              alt="LinkedIn"
            />
          </a>
        </Grid>
        <Grid container justify="center" className="footer-terms-section">
          <Typography className="footer-terms-section-text">
            <Link to="/privacy-policy" >Transparency</Link>
            {" | "}
            <Link to="/privacy-policy" >Do Not Sell My Info</Link>
            {" | "}
            <Link to="/privacy-policy" >Cookies</Link>
            {" | "}
            <Link to="/privacy-policy" >Privacy Policy</Link>
            {" | "}
            <a href="http://stats.pingdom.com/jsfl2c5t4huh">Status</a>
            {" | "}
            <Link to="/terms" >Legal</Link>
            {" | "}
            <Link to="/terms" >Terms of Service</Link>
          </Typography>
          <Typography className="footer-terms-section-text">
            Copyright © 2024 Mobi, Inc. All rights reserved.
          </Typography>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
