import React, { ReactNode, ReactElement } from 'react';
import { Box } from '@material-ui/core';
import { Curve } from 'components';

import './styles.sass';

type DarkSectionProps = {
  children?: ReactNode;
  top?: number;
};

/**
 * @returns {ReactElement}
 */
function DarkSection(props: DarkSectionProps): ReactElement {
  return (
    <Box className="dark-section" position="relative" top={`${props.top}rem`} marginBottom={`${props.top}rem`}>
      <Curve />
      <div className="dark-section-content">
        {props.children}
      </div>
    </Box>
  );
}

DarkSection.defaultProps = {
  top: 0,
};

export default DarkSection;
