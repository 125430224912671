import React from 'react';

import './styles.sass';

class ErrorBoundary extends React.PureComponent {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error);
    console.info(info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-content">
          <h4>
            Oops Sorry!
          </h4>
          <h6>
            You're using a web browser we don't support!
            <p>
              Try using latest version of Safari, Chrome or Firefox to visit our website.
            </p>
          </h6>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
